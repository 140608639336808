import { defineStore } from 'pinia';

export const useMyUtilsStore = defineStore({
  id: 'myUtilsStore',
  state: () => ({}),
  actions: {
    truncateSentence: (sentence: string, maxLength: number) => {
      // Check if sentence needs to be trimmed
      if (sentence.length > maxLength) {
        // Trim the sentence to the maximum length and add ellipsis
        return sentence.slice(0, maxLength - 3) + '...';
      }

      // If sentence is within the limit, return it as is
      return sentence;
    },
  },
});
